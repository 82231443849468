
.donate-container {
  text-align: center;
  margin-top: 20px;
}

.donate-button,
.cancel-button,
.next-button,
.submit-button {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  cursor: pointer;
}

.donate-button {
  background-color: #4CAF50;
  color: white;
}

.cancel-button {
  background-color: #f44336;
  color: white;
}

.next-button,
.submit-button {
  background-color: #008CBA;
  color: white;
}

.next-button:disabled,
.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 300px;
}

.modal-content span,
.modal-content input {
  color: black;
}

.token-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.token-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 2px solid black;
  border-radius: 8px;
  cursor: pointer;
}

.token-item.selected {
  border-color: #008CBA;
}

.token-image {
  width: 50px;
  height: 50px;
}

.token-item span {
  margin-top: 5px;
}

.amount-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

