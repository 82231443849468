.outer-sign-container {
    background-color: black;
    width: 100vw;
    height: 100vh;
}

.sign-container {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
    float: center;
    margin: auto;
    margin-top: 90px;
}

.sign-container h1 {
    margin-bottom: 1rem;
    color: #333;
}

.sign-container h2 {
    margin-bottom: 1.5rem;
    color: #555;
}

.error {
    color: red;
}

.sign-input,
input[type="email"],
input[type="password"] {
    width: 100%;
    padding: 0.75rem;
    margin: 0.5rem 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.sign-button {
    width: 100%;
    padding: 0.75rem;
    margin: 0.5rem 0;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.sign-button:hover {
    background-color: #0056b3;
}

.minor-button {
    width: 100%;
    padding: 0.75rem;
    margin: 0.5rem 0;
    background-color: #6c757d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.minor-button:hover {
    background-color: #5a6268;
}

/* Responsive Design */
@media (max-width: 768px) {
    .sign-container {
        padding: 1.5rem;
    }

    .sign-container button {
        font-size: 0.9rem;
    }
}

