
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

:root {
  --pfBgColor: #F7F7F7;
  --pfBlue: #0A33E4;
  --pfBorderRadius: 30px;
  --pfMaxWidth: 80%;
  --pfMobileMaxWidth: 90%;
  --buttonColor: #00D0FF;
  --altbuttoncolor: white;
  --donateButtonColor: #55D535;
  --buttonBorderColor: black;
  --buttonHighlightColor: #0000FF;
  --cardColor: #F7F7F7;
  /* --cardContainerColor: #000050; */
  --cardContainerColor: black;
  --fundBgColor: #000000;
  --hambColor: #00D0FF;
  --headercolor: black;
  --headerHeight: 78px;
  --landingmaincolor: #00D0FF;
  --loginblue: #00CE33;
  --logoBgColor: black;
  --navBarBgColor: #F7F7F7;
  --navBarBgHighlightColor: var(--buttonHighlightColor);
  --navBarLinkColor: black;
  --navBarLinkHighlightColor: white;
  --selectedColor: #00D0FF;
}

#root {
    align-items: center;
    justify-content: center;
    text-align: center;
}

* {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; */
  font-family: 'Montserrat', sans-serif;
  color: black;
  background-color: var(--pfBgColor);
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 20px;
  color: black;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

p {
  margin-bottom: 10px;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.button, button {
  display: inline-block;
  background-color: var(--buttonColor);
  color: black;
  padding: 10px 20px;
  margin: 2px;
  border: 2px solid var(--buttonBorderColor);
  border-radius: var(--pfBorderRadius);
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
}

.button:hover, button:hover {
  background-color: var(--buttonHighlightColor);
  color: white;
}

.minor-button {
    background-color: #6c757d;
}

.minor-button:hover {
    background-color: #5a6268;
}

input[type="text"], input[type="email"], input[type="password"] {
  padding: 10px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

input[type="radio"] {
  padding: 4px 4px;
  margin: 8px 20px;
  cursor: pointer;
}

input[type="submit"] {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #45a049;
}

select {
  width: 200px;
  height: 40px;
  border-radius: var(--pfBorderRadius);
  padding: 8px;
  margin: 8px 0;
}

.asterisk-font {
  font-size: 0.75em;
}

.selected {
  background-color: var(--selectedColor);
  color: white;
}

.unselected {
  background-color: white;
  color: black;
}

/*
.minor-button {
  background-color: grey;
  color: white;
}
 */

.horizontal-bar {
  background-color: black;
  width: 80%;
  height: 4px;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 80px;
}

.hero {
  justify-content: center;
  align-items: center;
  background-image: url('/public/images/hero.jpeg');
  /* background-image: linear-gradient(blue, lightblue); */
  background-size: cover;
  background-position: center;
  color: white;
  padding: 50px 0px;
  margin-bottom: 3.0rem;
  text-align: center;
}

.hero h1, .hero h2, .hero h3, .hero h4 {
  color: black;
}

.hero h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.hero h4 {
  font-size: 1.2rem;
  margin-top: 1.0rem;
  margin-bottom: 1.0rem;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.hero-content {
  background-color: #F7F7F7;
  border: 2px solid black;
  border-radius: var(--pfBorderRadius);
  width: 45%;
  margin: auto;
  margin-top: 70px;
  float: center;
  padding: 20px;
}

.pfheader {
    cursor: pointer;
}

.pfheader h1 {
    background-color: var(--buttonColor);
    border: 4px solid black;
    border-radius: var(--pfBorderRadius);
    font-size: 1.8em;
    width: 300px;
    margin: auto;
    padding: 8px;
}

.home-link {
  width: 100%;
  height: 100%;
}

.outer-card-container {
    margin: auto;
    margin-bottom: 30px;
    /*
    width: var(--pfMaxWidth);
    padding: 30px;
    background-color: var(--cardContainerColor);
    border: 2px solid black;
    border-radius: var(--pfBorderRadius);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
     */
}

.outer-card-container button {
    margin-top: 2px;
}

.outer-card-container h3 {
    color: black;
    font-size: 1.5rem;
}

.card-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.card {
    padding: 20px 20px;
    margin: 5px 10px;
    background-color: var(--cardColor);
    border: 2px solid black;
    border-radius: var(--pfBorderRadius);
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    text-align: left;
}

.card p {
    color: black;
    font-weight: 500;
}

.pf-main {
    padding-bottom: 30px;
}

.pf-main li {
    list-style-type: none;
}

.above-all {
    z-index: 4;
    background-color: lightgrey;
}

.error, .error h4 {
    color: red;
}

.status, .status h4 {
    color: green;
}

.spacing {
    width: 100%;
    height: 30px;
}

.fund {
    float: center;
    margin: 4em auto;
    padding: 30px;
    width: var(--pfMaxWidth);
    background-color: var(--fundBgColor);
    border: 2px solid black;
    border-radius: var(--pfBorderRadius);
}

.fund * {
    color: white;
}

.fund button {
    color: black;
}

.fund button:hover {
    color: white;
}

.fund img {
    border: 1px solid black;
}

.funds ol {
    list-style-type: none;
}

.fund-details {
    align-content: center;
    float: center;
    margin: 0;
    padding: 0;
}

.fund-details p {
    max-width: 600px;
    margin: 10px auto;
}

.fund-donation input[type="text"] {
    color: black;
}

.sharefundbutton {
    position: absolute;
    right: 12%;
}

.outerfundscontainer {
    margin-top: 2.0rem;
}

.outerfundscontainer h2 {
    color: black;
}

.navbar {
  display: flex;
  justify-content: right;
  background-color: var(--navBarBgColor);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.navbar-links {
  display: flex;
  justify-content: right;
  align-items: center;
}

.navbar h4 {
  position: absolute;
  top: 15px;
  left: 2px;
  width: 300px;
  font-size: 1.4rem;
  font-weight: 800;
}

.nav-link {
  width: 150px;
  height: 100%;
  color: var(--navBarLinkColor);
  text-decoration: underline;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 15px 0;
  margin: auto 20px;
}

.nav-link:hover {
  background-color: var(--navBarBgHighlightColor);
  color: var(--navBarLinkHighlightColor);
  font-weight: 900;
  text-decoration: none;
}

.nav-logo {
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
}

.landingpagebutton {
    padding: 15px 30px;
    font-size: 1.0rem;
    font-weight: 600;
    margin-bottom: 10px;
}

.altbuttoncolor {
    background-color: var(--altbuttoncolor);
}


/* Mobile menu */
.header {
    background-color: var(--headercolor);
    width: 100%;
    height: var(--headerHeight);
    display: inline-block;
    justify-content: right;
    float: right;
    padding: 20px 20px 20px 20px;
}

.header a {
    float: right;
    text-align: right;
    margin-left: 20px;
    margin-right: 20px;
    display: inline;
}

.header ul {
  list-style: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
}

.header ul a {
  color: white;
}

.header ul li {
  padding: 5px 5px 5px 5px;
  float: center;
  font-size: 1.2em;
  text-align: center;
}

.header ul li:hover {
  transform: scale(1.1);
  transition: 0.3s;
}

#brand {
  background-color: var(--logoBgColor);
  border: 2px solid var(--buttonColor);
  border-radius: var(--pfBorderRadius);
  font-weight: bold;
  font-size: 23px;
  /*
  display: inline;
  float: left;
   */
  position: absolute;
  top: 12px;
  left: 1px;
  padding: 0;
  margin-top: 0px;
  margin-left: 60px;
}

#brand a {
  color: var(--buttonColor);
  padding: 10px 5px;
  text-decoration: none;
}

#login,
#signup {
  border-radius: 5px;
  padding: 10px 5px 10px 5px;
  margin-right: 30px;
}

#login {
  border: 2px solid var(--loginblue);
  margin-left: 30px;
}

#signup {
  border: 2px solid var(--landingmaincolor);
}

#signup a {
  color: var(--landingmaincolor);
  text-decoration: none;
}

#login a {
  color: var(--loginblue);
  text-decoration: none;
}

#hamburger-icon {
  /*
  display: none;
  display: block;
  float: left;
   */
  visibility: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 12px 35px;
  cursor: pointer;
}

#hamburger-icon div {
  width: 35px;
  height: 3px;
  background-color: var(--hambColor);
  margin: 6px 0px;
  transition: 0.4s;
}

.open .bar1 {
  -webkit-transform: rotate(-45deg) translate(-6px, 6px);
  transform: rotate(-45deg) translate(-6px, 6px);
}

.open .bar2 {
  opacity: 0;
}

.open .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -8px);
  transform: rotate(45deg) translate(-6px, -8px);
}

.open .mobile-menu {
  visibility: visible;
  position: absolute;
  top: calc(var(--headerHeight) - 25px);
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  width: 100vw;
  height: 300vh;
}

.mobile-menu {
  visibility: hidden;
  background-color: black;
  /*
  display: none;
  position: absolute;
  top: 70px;
  left: 0;
  float: center;
  justify-content: center;
   */
}

.mobile-menu li {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mobile-menu a {
  color: var(--landingmaincolor);
}


.pricing-header {
    height: 65px;
}

nav a {
    cursor: pointer;
    font-size: 0.6em;
    font-weight: 600;
    text-decoration: none;
}

.sticky {
    position: fixed;
    top: 0;
    left: -20px;
    z-index: 3;
    margin-left: 0;
    padding-left: 0;
}

.downarrowsymbol {
    padding-left: 8px;
}

#login-button {
    visibility: hidden;
    display: block;
    background-color: rgb(0, 0, 0);
    color: var(--landingmaincolor);
    border: 2px solid var(--landingmaincolor);
    border-radius: var(--pfBorderRadius);
    font-size: 1em;
    vertical-align: middle;
    float: right;
    margin-left: 3em;
    margin-right: 2px;
    padding: 10px 20px;
    cursor: pointer;
}


/* Pages */
.about-page, .contact-page {
    padding: 25px;
    width: 70%;
    max-width: 850px;
    margin: auto;
    float: center;
}

.about-page h1, .contact-page h1 {
    margin-top: 80px;
}

.about-page h2, .about-page h3, .about-page h4 {
    margin-top: 30px;
    margin-bottom: 12px;
}

.contact-page h2 {
    margin-top: 25px;
    margin-bottom: 10px;
}

.contact-page h5 {
    margin-top: 20px;
    margin-bottom: 10px;
}

.startfund-container {
    padding: 25px;
    width: 65%;
    margin: auto;
    float: center;
}

.startfund-container h4 {
    margin-top: 20px;
}

/*
.sign-container {
    float: center;
    margin: 0 auto;
    padding: 40px;
    width: 90%;
    height: 88vh;
    background-color: var(--fundBgColor);
    border: 2px solid black;
    border-radius: var(--pfBorderRadius);
}

.sign-container h1, .sign-container h2 {
    margin-bottom: 50px;
    color: white;
}

.sign-container button {
    font-size: 1.3rem;
    font-weight: 600;
    margin-left: 15px;
    margin-right: 15px;
}
 */

.donate-button {
    background-color: var(--donateButtonColor);
}

.donate-link {
    background-color: var(--donateButtonColor);
    color: black;
    border: 2px solid var(--buttonBorderColor);
    border-radius: var(--pfBorderRadius);
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    padding: 10px 20px;
}

.console h1 {
    color: black;
}

.console-top {
    display: flex;
    justify-content: right;
    /* background-color: var(--selectedColor); */
    background-image: linear-gradient(white, lightblue);
    padding-top: 10px;
    padding-bottom: 10px;
}

.console-top h1 {
  position: absolute;
  top: 17px;
  left: 2px;
  width: 300px;
  font-size: 1.4rem;
  font-weight: 800;
}

.console-top button {
  margin: auto 15px;
}

.mobile-title {
    display: none;
    width: 100%;
    padding-top: 8px;
}

footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

/* Loading animation */
.loading-container {
  padding: 0;
  background: #000;
}

.loading-container h2 {
  color: white;
  padding-bottom: 50px;
}

.wave-container {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
}

.wave-container bodY {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.wave {
  width: 5px;
  height: 100px;
  background: linear-gradient(45deg, cyan, #fff);
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}

.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}





/* Mobile styles */
@media (max-width: 768px) {
    html, body {
        overflow-x: hidden;
    }

    body {
        position: relative;
    }

    .header {
        /* height: 40px; */
    }

    .header nav {
        display: none;
    }

    .mobile-hidden {
        visibility: hidden;
    }

    .mobile-graphic {
        width: 92%;
    }

    .hero-content {
        width: 90%;
    }

    .card-container {
        grid-template-columns: 1fr;
    }

    .card {
        padding: 15px 20px;
        margin: 5px 0px;
    }

    #mobile-menu a {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    #login, #signup {
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 0px;
        margin-right: 0px;
    }

    #hamburger-icon {
        visibility: visible;
    }

    #login-button {
        /*
        visibility: visible;
        margin-left: 10px;
         */
    }

    #brand {
        font-size: 1.0em;
        position: absolute;
        top: 4px;
        left: 60px;
    }

    .error, .status {
        font-size: 0.8em;
    }

    .console-top {
        padding-top: 0px;
    }

    .console-top h1 {
        visibility: hidden;
    }

    .mobile-title {
        display: inline;
    }

    .console-top button {
        height: 60px;
        padding: 2px 2px;
        margin: auto 1px;
        font-size: 0.9em;
        font-weight: 800;
        border-radius: 16px;
    }

    .fund-details h3 {
    }

    .sharefundbutton {
        visibility: hidden;
    }

    .outer-card-container, .fund {
        width: var(--pfMobileMaxWidth);
    }

    .about-page, .contact-page {
        padding: 10px 5px;
        width: 99%;
    }

    .about-page a {
        font-size: 0.9em;
    }
}

