
.features-section {
  padding: 20px 20px 60px 20px;
  background-color: #f5f5f5;
  text-align: center;
}

.features-section h2 {
  font-size: 36px;
  margin-bottom: 40px;
}

.features-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.feature-card {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.feature-image {
  width: 120px;
  height: 120px;
  object-fit: contain;
  margin-bottom: 20px;
}

.feature-card h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

.feature-card p {
  font-size: 16px;
  color: #666666;
}


/* Mobile styles */
@media (max-width: 768px) {
    .features-section h2 {
        font-size: 32px;
    }
}

